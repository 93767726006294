import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Flex } from "rebass/styled-components";
import { Link } from "gatsby";
import { Content, Title, Info, BtnGroup, Btn } from "./style";
import { Container } from "../../../global";

import LeftIcon from "../../../../images/csr/left.png";
import RightIcon from "../../../../images/csr/right.png";
import BtnIcon from "../../../../images/csr/btn.png";
const Paragraph = props => (
  <Text
    variant="body"
    mb="2rem"
    as="p"
    fontSize={["sm", null, "sm", null, "sm"]}
    textAlign="left"
    style={{ textDecoration: "none" }}
    {...props}
  />
);

const OutOfSchool = () => {
  return (
    <Container
      position="relative"
      style={{ width: "100vw", maxWidth: "100vw", padding: "0", background: "rgb(244, 252, 250);" }}
    >
      <Content>
        <Title>Play4aChild</Title>
        <Info>
          Play4aChild ingeniously merges transactional and leisure activities into everyday OPay usage, such as playing
          games, paying utility bills, and making payments for goods and services. This approach provides a
          quantifiable, marketable, and allocable platform, promoting a socio-economic sustainable lifestyle through
          emerging digital technologies.
        </Info>
        <Flex
          justifyContent="center"
          marginBottom={["20px", "20px", "20px", "50px", "50px"]}
          marginTop={["20px", "20px", "20px", "50px", "50px"]}
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Box maxWidth={["100%", "100%", "100%", "45%", "45%"]} marginRight={["0", "0", "0", "100px", "100px"]}>
            <img src={LeftIcon}></img>
          </Box>
          <Box maxWidth={["100%", "100%", "100%", "45%", "45%"]} marginRight={["0", "0", "0", "100px", "100px"]}>
            <img src={RightIcon}></img>
          </Box>
        </Flex>
        <Info>
          In alignment with OPay's commitment to social responsibility, Play4aChild serves as a bottom-up strategy to
          address the challenges faced by out-of-school children in Nigeria. With over 20 million such children, as
          reported by UNESCO in 2022, OPay is dedicated to supporting the government's efforts to reduce this alarming
          statistic.
        </Info>
        <BtnGroup>
          <Btn onClick={() => window.open("https://opayapp.onelink.me/2h9f")}>
            <img src={BtnIcon}></img>
            <span>ClickheretoPlay4aChild</span>
          </Btn>
          <Btn>
            <img src={BtnIcon}></img>
            <Link to={"/apply-now"}>
              <span>Click here to for the 2024 NGO call for applications</span>
            </Link>
          </Btn>
        </BtnGroup>
      </Content>
    </Container>
  );
};

export default OutOfSchool;
