import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
// import Navigation from "../components/common/navigation/navigation";
import Navigation from "../components/common/navigation/navigation-new";
import Header from "../components/sections/play4aChild/header/header";
import Content from "../components/sections/play4aChild/content/content";
import Footer from "../components/sections/footer/index-new";
const csr = () => (
  <Layout>
    <SEO title="OPay" />
    <Navigation background="white" />
    <Header />
    <Content />
    {/* <Objective />
    <PicList />
    <CardList /> */}
    <Footer />
  </Layout>
);

export default csr;
