import styled from "styled-components";

const Content = styled.div`
  padding: 20px;
  background: rgba(29, 207, 159, 0.2);
  ${({ theme }) => theme.mq.md`
      padding: 70px;
      background: rgba(29, 207, 159, .2);
  `}
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #210f60;
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.md`
    font-size: 40px;
    margin-bottom: 30px;
  `}
`;

const Info = styled.div`
  font-size: 12px;
  color: #210f60;
  line-height: 17px;
  ${({ theme }) => theme.mq.md`
    font-size: 20px;
    line-height: 37px;
  `}
`;

const BtnGroup = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.md`
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    justify-content: center;
    
  `}
`;

const Btn = styled.div`
  padding: 0 8px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background: #1dcf9f;
  justify-content: center;
  font-size: 11px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 12px;
  img {
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }

  ${({ theme }) => theme.mq.md`
    padding: 0 30px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    background: #1DCF9F;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    &:first-child {
      margin-right: 150px;
    }
    
    img {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  `}
`;

export { Content, Title, Info, BtnGroup, Btn };
