import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Heading as InfoHeader, Flex } from "rebass/styled-components";
import {
  StyledHeaderBg,
  StyledContainer,
  ContentContainer,
  DataContent,
  DataItem,
  ItemLabel,
  ItemValue,
} from "./style";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      csrBg: file(sourceInstanceName: { eq: "product" }, name: { eq: "detail" }) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box mt={["58px"]} id="top">
      <StyledContainer>
        <StyledHeaderBg objectFit="contain" fluid={data.csrBg.childImageSharp.fluid}></StyledHeaderBg>
      </StyledContainer>
      <DataContent>
        <Flex justifyContent="space-between" alignItems={"center"} flexWrap={"wrap"}>
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>2K+</ItemLabel>
              <ItemValue>Children Supported</ItemValue>
            </DataItem>
          </Box>
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>15</ItemLabel>
              <ItemValue>Primary & Secondary Schools Benefitted</ItemValue>
            </DataItem>
          </Box>
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>12</ItemLabel>
              <ItemValue>Communities Reached in 2 Cities</ItemValue>
            </DataItem>
          </Box>
        </Flex>
      </DataContent>
    </Box>
  );
};

export default Header;
